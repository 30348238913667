import NavBar from "../navbar/NavBar";

function Articles() {
  return (
    <div className="articles">
      <NavBar />
    </div>
  );
}

export default Articles;
