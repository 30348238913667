export const mySkills = [
  {
    language: "Golang",
    className: "golang",
    level: "advanced",
    icon: "devicon-go-plain css",
  },
  {
    language: "Typescript",
    className: "typescript",
    level: "advanced",
    icon: "devicon-typescript-plain css",
  },
  {
    language: "React",
    className: "react",
    level: "advanced",
    icon: "devicon-react-original css",
  },
  {
    language: "Javascript",
    className: "javascript",
    level: "advanced",
    icon: "devicon-javascript-plain css",
  },
  {
    language: "HTML",
    className: "html",
    level: "advanced",
    icon: "devicon-html5-plain css",
  },
  {
    language: "CSS",
    className: "css",
    level: "advanced",
    icon: "devicon-css3-plain css",
  },
  {
    language: "sass",
    className: "sass",
    level: "advanced",
    icon: "devicon-sass-plain css",
  },
  {
    language: "Node.js",
    className: "nodejs",
    level: "advanced",
    icon: "devicon-nodejs-plain css",
  },
  {
    language: "Express.js",
    className: "expressjs",
    level: "advanced",
    icon: "devicon-express-original css",
  },
  {
    language: "MongoDB",
    className: "mongodb",
    level: "advanced",
    icon: "devicon-mongodb-plain css",
  },
  {
    language: "Python",
    className: "python",
    level: "advanced",
    icon: "devicon-python-plain css",
  },
  {
    language: "Flutter",
    className: "flutter",
    level: "advanced",
    icon: "devicon-flutter-plain css",
  },
  {
    language: "Swift",
    className: "swift",
    level: "advanced",
    icon: "devicon-swift-plain css",
  },
  {
    language: "Git",
    className: "git",
    level: "advanced",
    icon: "devicon-git-plain css",
  },
  {
    language: "Arduino",
    className: "arduino",
    level: "advanced",
    icon: "devicon-arduino-plain css",
  },
];